<template>
  <div
    v-show="currentStep !== 'quote'"
    class="lg:text-left grid lg:grid-cols-step gap-y-4 mt-12 container mx-auto"
  >
    <div class="px-4 sm:px-8 xl:pr-16">
      <nav aria-label="Progress">
        <div class="flex space-x-4 text-blue-700 lg:mb-12 md:mb-8 mb-4">
          <h1 class="text-9xl leading-none w-40">0{{ currentStep }}</h1>
          <h6 class="text-4xl leading-10 pt-4">/03</h6>
        </div>
        <ol class="overflow-hidden">
          <li
            v-for="(step, stepIdx) in steps"
            :key="step.name"
            :class="[stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative']"
          >
            <template v-if="step.step < currentStep">
              <div
                v-if="stepIdx !== steps.length - 1"
                class="
                  -ml-px
                  absolute
                  mt-0.5
                  top-4
                  left-4
                  w-0.5
                  h-full
                  bg-blue-600
                "
                aria-hidden="true"
              />
              <router-link
                :to="`/onboarding/step/` + step.step"
                class="relative flex items-start group"
              >
                <span class="h-9 flex items-center">
                  <span
                    class="
                      relative
                      z-10
                      w-8
                      h-8
                      flex
                      items-center
                      justify-center
                      bg-blue-600
                      rounded-full
                      group-hover:bg-blue-800
                    "
                  >
                    <CheckIcon class="w-5 h-5 text-white" aria-hidden="true" />
                  </span>
                </span>
                <span class="ml-4 min-w-0 flex flex-col">
                  <span class="text-xs font-semibold tracking-wide uppercase">{{
                    step.name
                  }}</span>
                  <span class="text-sm text-gray-500">{{
                    step.description
                  }}</span>
                </span>
              </router-link>
            </template>
            <template
              v-else-if="step.step === currentStep"
              condition="step.status === 'current'"
            >
              <div
                v-if="stepIdx !== steps.length - 1"
                class="
                  -ml-px
                  absolute
                  mt-0.5
                  top-4
                  left-4
                  w-0.5
                  h-full
                  bg-gray-300
                "
                aria-hidden="true"
              />
              <router-link
                :to="`/onboarding/step/` + step.step"
                class="relative flex items-start group"
                aria-current="step"
              >
                <span class="h-9 flex items-center" aria-hidden="true">
                  <span
                    class="
                      relative
                      z-10
                      w-8
                      h-8
                      flex
                      items-center
                      justify-center
                      bg-white
                      border-2 border-blue-600
                      rounded-full
                    "
                  >
                    <span class="h-2.5 w-2.5 bg-blue-600 rounded-full" />
                  </span>
                </span>
                <span class="ml-4 min-w-0 flex flex-col">
                  <span
                    class="
                      text-xs
                      font-semibold
                      tracking-wide
                      uppercase
                      text-blue-600
                    "
                    >{{ step.name }}</span
                  >
                  <span class="text-sm text-gray-500">{{
                    step.description
                  }}</span>
                </span>
              </router-link>
            </template>
            <template v-else>
              <div
                v-if="stepIdx !== steps.length - 1"
                class="
                  -ml-px
                  absolute
                  mt-0.5
                  top-4
                  left-4
                  w-0.5
                  h-full
                  bg-gray-300
                "
                aria-hidden="true"
              />
              <router-link
                :to="`/onboarding/step/` + step.step"
                class="relative flex items-start group"
              >
                <span class="h-9 flex items-center" aria-hidden="true">
                  <span
                    class="
                      relative
                      z-10
                      w-8
                      h-8
                      flex
                      items-center
                      justify-center
                      bg-white
                      border-2 border-gray-300
                      rounded-full
                      group-hover:border-gray-400
                    "
                  >
                    <span
                      class="
                        h-2.5
                        w-2.5
                        bg-transparent
                        rounded-full
                        group-hover:bg-gray-300
                      "
                    />
                  </span>
                </span>
                <span class="ml-4 min-w-0 flex flex-col">
                  <span
                    class="
                      text-xs
                      font-semibold
                      tracking-wide
                      uppercase
                      text-gray-500
                    "
                    >{{ step.name }}</span
                  >
                  <span class="text-sm text-gray-500">{{
                    step.description
                  }}</span>
                </span>
              </router-link>
            </template>
          </li>
        </ol>
      </nav>
    </div>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" :key="$route.fullPath"></component>
      </keep-alive>
    </router-view>
  </div>
  <router-view v-if="currentStep === 'quote'" v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" :key="$route.fullPath"></component>
    </keep-alive>
  </router-view>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/solid";

import { useRoute } from "vue-router";
import { watch, ref } from "vue";

const steps = [
  {
    name: "TYPE OF APPLICATION",
    description: "Purchasing? Selling? or may be both?.",
    step: 1,
    status: "current",
  },
  {
    name: "PROPERTY INFORMATION",
    description: "Information about the property",
    step: 2,
    status: "complete",
  },
  {
    name: "PERSONAL INFORMATION",
    description: "Few personal details",
    step: 3,
    status: "upcoming",
  },
];

export default {
  components: {
    CheckIcon,
  },
  setup() {
    const route = useRoute();
    const currentStep = ref(route.name);

    watch(
      () => route.name,
      async (newStep) => {
        currentStep.value = newStep;
      }
    );

    return {
      steps,
      currentStep,
    };
  },
};
</script>
